<template>
    <modal class="invoiceRecordModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">会员充值开票记录</div>
        <div class="modal-body">
            <div class="info-box">
                <div class="title">会员信息 <div class="bnt-daochu" @click="exportExcel"><i class="iconfont icon-daochu"></i>导出报表</div></div>
                <div class="content-box">
                    <div class="lable-box">卡号：{{memberInfo?.Card_No}}</div>
                    <div class="lable-box">客户姓名：{{memberInfo?.MemberName}}</div>
                    <div class="lable-box">手机号：{{memberInfo?.Mobile}}</div>
                    <div class="lable-box">会员等级：{{memberInfo?.Bestech_Card_KindName}}</div>
                </div>
            </div>
            <div class="table-box grey-table">
                <el-table ref="tableEl" border :data="tableListPage" height="400" style="width: 100%;"  
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column type="index" label="序号" width="42" >
                        <template #default="scope">
                            {{scope.$index+pagesize*(currentPage-1)+1}}
                        </template>
                    </el-table-column>
                    <el-table-column type="edit" label="操作" width="70"> 
                        <template #default="scope">
                            <el-button type="text" size="small" v-if="scope.row.Operate_Type==1" @click="CardCancel(scope.row)">撤销</el-button>
                            <el-button  type="text" size="small" v-show="false">补打</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="money" prop="InvoiceMoney" label="开票金额" min-width="60" align="right" data-format="number"></el-table-column> 
                    <el-table-column prop="Operator_Name" label="操作人" min-width="50" align="left"></el-table-column>
                    <el-table-column prop="Operate_Time" label="操作时间" width="130" align="left"></el-table-column>
                    <el-table-column prop="Door_Name" label="操作门店" min-width="120" align="left"></el-table-column>
                    <el-table-column class-name="money" prop="RemainInvoiceMoney" label="剩余开票金额" width="90" align="right" data-format="number"></el-table-column>
                    <el-table-column prop="Operate_Type" label="操作类型" width="80" >
                        <template #default="scope">
                            <span>{{({1:'开发票',2:'撤销开票',3:'已撤销'})[scope.row.Operate_Type]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Recharge_Serial" label="充值流水号" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Invoice_No" label="发票号" min-width="100" align="left"> </el-table-column>
                    <el-table-column prop="Remark" label="备注" min-width="100" align="left"> </el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                :data="tableFilter"
                @page-change="(list)=>tableListPage=list" 
                :pageSizes="[20,30,50,100]"
                :pageSize="pagesize"
                 @size-change="(size)=>pagesize=size"></table-page-btn>
            <div class="describe">注：开票记录仅显示近一年以内的开票记录</div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
    </modal>
</template>

<script>

// 开票记录
export default {
    name:'invoiceRecordModel',
    emits:["close","refresh"],
    props:{
        /**是否显示弹层 */
        isShow:{
            type:Boolean,
            default:false
        },
        /** 传入 会员信息 */
        memberInfo:{
            type:[Object,String],
            default:null
        }
    },
    data(){
        return{
            /**开票记录数据 */
            tableData:[],
            tableListPage:[],
            currentPage:1,
            pagesize:20,//每页的数据条数
        }
    },
    computed:{
        /**开票信息 筛选 近一年内数据 */
        tableFilter(){
            let list=this.tableData;
            
            if(list?.length>0){
                let day=new Date();
                let year=day.getFullYear()-1;
                let BRpt_Date=day.Format(year+"-MM-dd 00:00:00")//开始日期
                let ERpt_Date=day.Format("yyyy-MM-dd 23:59:59");//结束日期
                list=list?.filter(it=> it.Operate_Time>=BRpt_Date && it.Operate_Time<=ERpt_Date)
            }
            return list;
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        /**初始化数据 */
        init(){
            this.loadData();
        },
        //加载数据
        loadData(){
            this.userInfo=this.$auth.getUserInfo();
            this.tableData=[]
            let param={
                User_ID:this.userInfo?.User_ID,
                Card_No:this.memberInfo?.Card_No, //卡号
                Card_AutoID:this.memberInfo?.Bestech_Card_AutoID,//卡ID
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Operator_Pos:this.userInfo?.Site_Name, //操作站点
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberRechargeInvoices",param).then((data)=>{
                loading.close();
                console.log(data)
                if(data.ResponseHeader.ResultCode==0){
                    this.tableData=data.ResponseBody;
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('查询失败：'+e);
                console.log('查询失败：'+e);
            })
        },
        /**重印小票 */
        printReceipt(item){
            const loading = this.$loading({
                text: "请求打印数据中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetMemberBizPrintContent",{
                Operate_User:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID,
                Biz_Type:1,
                Biz_ID:item.Crm_BizID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody){
                        this.$webBrowser.posPrint(d.ResponseBody);
                        this.$message.success("已发送打印指令");
                    }else{
                        this.$message.error('请求设备没有相关打印设置!');
                    }
                    
                }else{
                    this.$message.error("请求打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求打印数据失败：'+e);
                console.log('请求打印数据失败：'+e);
            })
        },
        /**撤销 */
        CardCancel(item){
            this.userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:this.userInfo?.User_ID,
                Issue_AutoID:item.Issue_AutoID,//开票记录ID,
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Operator_Pos:this.userInfo?.Site_Name, //操作站点
            }
            const loading = this.$loading({
                text: "撤销中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.CancelMemberRechargeInvoice",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    this.loadData();
                    this.$emit("refresh");
                    this.$message.success("撤销成功");
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('撤销失败：'+e);
                console.log('撤销失败：'+e);
            })          
        },
        /**查询支付结果中 */
        selectResult(item){
            this.userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:this.userInfo?.User_ID,
                Crm_BizID:item.Crm_BizID,
                Operate_User:this.userInfo?.Login_Name,//操作员
                Operate_PosName:this.userInfo?.Site_Name, //操作站点
            }
            
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryCrmBizResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    item.Is_Canceled = false;
                    item.Operate=''
                    if(data.ResponseBody?.OpenCardOK || data.ResponseBody.RechargeOK || data.ResponseBody?.ReissueOK){
                        item.BizStatus='成功';
                        item.Biz_Status=2;
                    }
                    this.$message.success("查询完成");
                }else{
                    this.selectReadCard();
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('撤销失败：'+e);
                console.log('撤销失败：'+e);
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(this.$refs.tableEl){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.tableEl,
                    titleName:"会员充值开票记录报表",
                    list:this.tableFilter
                })
            }
        },
    }
}
</script>

<style lang="scss">
@import './invoiceRecordModel.scss'
</style>